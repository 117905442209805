import { extend } from "vee-validate";
import { ArmazenamentoService } from "@/services";

//Regra para validar se descrição já foi cadastrada
extend("descricaoCadastrada", (value, [descricao]) => {
    //remove os espaços desnecessários declarados no início e/ou no final de uma string
    value = value.trim();
    //Verifica se o campo é vazio
    if (value == "") { return 'Esse campo é obrigatório'; }
    //Edição: valor igual descricao atual retorna verdadeiro
    if (value == descricao) { return true; }
    //verifica a descrição 
    return ArmazenamentoService.buscarPorDescricao(value).then(resposta => {
        if (resposta.status == 200 && (resposta.data !== null && resposta.status !== undefined)) {
            return 'Descrição já cadastrada';
        }
        return true;
    });
});

export const rules = {
    descricaoCadastrada: {
        descricaoCadastrada: true
    },
};
